import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col } from "react-bootstrap"
import graph1 from "../../images/topic-1/step-1/graph-1.svg"
import graph2 from "../../images/topic-1/step-1/graph-2.svg"
import graph3 from "../../images/topic-1/step-1/graph-3.svg"
import graph1_fr from "../../images/topic-1/step-1/graph-1_fr.svg"
import graph2_fr from "../../images/topic-1/step-1/graph-2_fr.svg"
import graph3_fr from "../../images/topic-1/step-1/graph-3_fr.svg"
import expert1 from "../../images/topic-1/step-1/prof-barrios.png"
import expert2 from "../../images/topic-1/step-1/prof-nielsen.png"
import expert3 from "../../images/topic-1/step-1/prof-cho.png"
import BottomNav from "../../components/bottomNav"
import MyBreadcrumb from "../../components/myBreadcrumb"
import Instructions from "../../components/instructions"
import parse from "html-react-parser"
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const ExpGrowthPageStep1 = ({ location }) => {
  const { languages, originalPath, language } = useI18next()
  const { t } = useTranslation()

  const pageContent = {
    pageTitle: parse(t("Topic1_Step1_pageTitle")),
    breadcrumbs: {
      topicLink: parse(t("Topic1_Step1_breadcrumbs.topicLink")),
      topicTitle: parse(t("Topic1_Step1_breadcrumbs.topicTitle")),
      secondLink: "",
      secondTitle: "",
      currentTitle: parse(t("Topic1_Step1_breadcrumbs.currentTitle")),
    },
    title: parse(t("Topic1_Step1_title")),
    topText: parse(t("Topic1_Step1_topText")),
    instructions: parse(t("Topic1_Step1_instructions")),
    experts: [
      {
        name: parse(t("Topic1_Step1_experts[0].name")),
        img: expert1,
        alt: parse(t("Topic1_Step1_experts[0].alt")),
        graph: language == "en" ? graph1 : graph1_fr,
        graphAlt: parse(t("Topic1_Step1_experts[0].graphAlt")),
        text: parse(t("Topic1_Step1_experts[0].text")),
      },
      {
        name: parse(t("Topic1_Step1_experts[1].name")),
        img: expert2,
        alt: parse(t("Topic1_Step1_experts[1].alt")),
        graph: language == "en" ? graph2 : graph2_fr,
        graphAlt: parse(t("Topic1_Step1_experts[1].graphAlt")),
        text: parse(t("Topic1_Step1_experts[1].text")),
      },
      {
        name: parse(t("Topic1_Step1_experts[2].name")),
        img: expert3,
        alt: parse(t("Topic1_Step1_experts[2].alt")),
        graph: language == "en" ? graph3 : graph3_fr,
        graphAlt: parse(t("Topic1_Step1_experts[2].graphAlt")),
        text: parse(t("Topic1_Step1_experts[2].text")),
      },
    ],
    bottomText: parse(t("Topic1_Step1_bottomText")),
  }

  // =========================================================================================================

  const [tab, setTab] = useState(-1)
  // =========================================================================================================
  return (
    <Layout>
      <SEO title={pageContent.pageTitle} />
      <MyBreadcrumb
        topicLink={pageContent.breadcrumbs.topicLink}
        topicTitle={pageContent.breadcrumbs.topicTitle}
        secondLink={pageContent.breadcrumbs.secondLink}
        secondTitle={pageContent.breadcrumbs.secondTitle}
        currentTitle={pageContent.breadcrumbs.currentTitle}
      />
      <div className="homeContent">
        <Row>
          <Col sm="12">
            {" "}
            <h3 className="blueTitleOFF">{pageContent.title}</h3>
            <p>{pageContent.topText}</p>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="12">
            <Instructions content={pageContent.instructions} />
          </Col>
        </Row>

        <div className="groupItems" style={{ padding: "30px" }}>
          <Row style={{ padding: "0px 100px" }}>
            <Col sm="6" md="4" lg="4" className="text-center">
              <div className="hoverMe">
                <img
                  className={tab == 0 ? "roundImg activeImg" : "roundImg"}
                  src={pageContent.experts[0].img}
                  alt={pageContent.experts[0].alt}
                  style={{ width: "90%" }}
                  onClick={() => {
                    setTab(0)
                  }}
                />
                <h4>{pageContent.experts[0].name}</h4>
              </div>
            </Col>
            <Col sm="6" md="4" lg="4" className="text-center">
              <div className="hoverMe">
                <img
                  className={tab == 1 ? "roundImg activeImg" : "roundImg"}
                  src={pageContent.experts[1].img}
                  alt={pageContent.experts[1].alt}
                  style={{ width: "90%" }}
                  onClick={() => {
                    setTab(1)
                  }}
                />
                <h4>{pageContent.experts[1].name}</h4>
              </div>
            </Col>
            <Col sm="6" md="4" lg="4" className="text-center">
              <div className="hoverMe">
                <img
                  className={tab == 2 ? "roundImg activeImg" : "roundImg"}
                  src={pageContent.experts[2].img}
                  alt={pageContent.experts[2].alt}
                  style={{ width: "90%" }}
                  onClick={() => {
                    setTab(2)
                  }}
                />
                <h4>{pageContent.experts[2].name}</h4>
              </div>
            </Col>
          </Row>
          <br />
          <div>
            <Row hidden={tab != 0}>
              <Col md="6" lg="8">
                {pageContent.experts[0].text}
              </Col>
              <Col md="6" lg="4">
                <img
                  src={pageContent.experts[0].graph}
                  alt={pageContent.experts[0].graphAlt}
                />
                <p className="smText">
                  {parse(t("Topic1_Step1_experts[0].graphText"))}
                </p>
              </Col>
            </Row>
            <Row hidden={tab != 1}>
              <Col md="6" lg="8">
                {pageContent.experts[1].text}
              </Col>
              <Col md="6" lg="4">
                <img
                  src={pageContent.experts[1].graph}
                  alt={pageContent.experts[1].graphAlt}
                />
                <p className="smText">
                  {parse(t("Topic1_Step1_experts[1].graphText"))}
                </p>
              </Col>
            </Row>
            <Row hidden={tab != 2}>
              <Col md="6" lg="8">
                {pageContent.experts[2].text}
              </Col>
              <Col md="6" lg="4">
                <img
                  src={pageContent.experts[2].graph}
                  alt={pageContent.experts[2].graphAlt}
                />
                <p className="smText">
                  {parse(t("Topic1_Step1_experts[2].graphText"))}
                </p>
              </Col>
            </Row>
          </div>
        </div>
        <br />

        <p hidden={tab != 0 && tab != 1 && tab != 2}>
          {pageContent.bottomText}
        </p>

        <hr />

        <span className="alignRight">
          <BottomNav
            prevName={parse(t("Overview"))}
            btnLinkPrev="/exponentialgrowth/"
            btnLinkNext="/exponentialgrowth/step2"
            nextName={parse(t("Next"))}
            disabled={tab != 0 && tab != 1 && tab != 2}
          />
        </span>
      </div>
      <br />
    </Layout>
  )
}

export default ExpGrowthPageStep1
